import React, { PureComponent } from 'react';
import { Pagination } from '@xo-union/tk-ui-pagination';

export default class UnionPagination extends PureComponent {
  constructor(props) {
    super(props);
    const { href } = props;
    this.joiner = href.indexOf('?') === -1 ? '?' : '&';
    this.renderLink = this.renderLink.bind(this);
  }

  formatAnalyticsData() {
    const { analyticsData } = this.props;
    const result = {};
    for ( var i in analyticsData ) {
      result[`data-${i}`] = analyticsData[i];
    }
    return result;
  }

  renderLink({
    pageData: {
      page,
    },
    pageLinkProps,
  }) {
    const { href, nofollow } = this.props;
    const linkProps = {
      ...this.formatAnalyticsData(),
      ...pageLinkProps,
      rel: nofollow ? 'nofollow' : '',
    }
    const link = page === 1 ? href : `${href}${this.joiner}page=${page}`;
    /* eslint-disable-next-line jsx-a11y/anchor-has-content */
    return (<a href={link} {...linkProps} />);
  }

  render() {
    const { activePage, totals } = this.props;
    return (
      <div>
        <Pagination activePage={activePage} totalPageCount={totals} renderLink={this.renderLink} />
      </div>
    );
  }
}
