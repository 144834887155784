import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from '@xo-union/react-css-modules';
import { clearToast } from '../../utilities/reducers/favorite-redux';
import { Snackbar } from '@xo-union/tk-component-alerts';
import styles from './FavoriteToast.css';

export class FavoriteToastContainer extends Component {

  constructor(props) {
    super(props);
    this.snackbar = React.createRef();
  }

  componentDidUpdate() {
    const { toast, clearToast } = this.props;
    if (toast) {
      this.snackbar.current.showAndDismiss({
        data: toast,
        dismissTimeout: 4000
      });
      clearToast();
    }
  }

  render() {
    return (
      <Snackbar ref={this.snackbar} classes={compose(styles)}>
        {(data) => {
          return (<a href="/my-wedding-vision/topics/ideas" target="_blank" tabIndex={data ? 0 : -1}>{data}</a>)
        }}
      </Snackbar>
    )
  }
}

export function mapStateToProps(state) {
  return {
    toast: state.favoriteData.toast
  };
}

export const mapDispatchToProps = {
  clearToast: clearToast
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteToastContainer);
