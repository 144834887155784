import * as membership from '@xo-union/store-membership-redux';
import * as actions from './action_creators';

export const favoriteMiddleware = ({
                                     clear = actions.clearFavorites,
                                     fetch = actions.fetchFavorites,
                                   } = {}) => () => next => (action) => {
  const actionResult = next(action);

  /* eslint-disable default-case */
  switch (action.type) {
    case membership.LOG_OUT_SUCCESS:
      next(clear());
      location.href = '/';
      break;
    case membership.FETCH_SUCCESS:
    case membership.LOG_IN_SUCCESS:
    case membership.SIGN_UP_SUCCESS:
      next(fetch());
      break;
  }

  return actionResult;
};