import ReactOnRails from 'react-on-rails';

import Header from '../components/Header';
import Favorite from '../components/Favorite';
import FavoriteToast from '../components/FavoriteToast';
import Footer from '../components/Footer';
import IconAgent from '../components/IconAgent';
import FilterComponent from '../components/FilterComponent';
import Spinner from '../components/Spinner';
import HeroSection from '../components/HeroSection';
import PhotoListingImage from '../components/PhotoListingImage';
import UnionPagination from '../components/union-pagination';

ReactOnRails.register({
  Header,
  HeroSection,
  FilterComponent,
  PhotoListingImage,
  Favorite,
  IconAgent,
  FavoriteToast,
  Footer,
  Spinner,
  UnionPagination,
});
