import React, { PureComponent } from 'react';
import FooterNav from '@xo-union/tk-component-footer-nav/remote';
import ReactDOMServer from 'react-dom/server';

export default class Footer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { FooterState: false };
        this.setFooterNav = this.setFooterNav.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.setFooterNav);
    }

    setFooterNav() {
        this.setState({ FooterState: true });
        window.removeEventListener('scroll', this.setFooterNav);
    }

    render() {
        const { FooterState } = this.state;
        const { tag } = this.props;
        const footerProps = {
            metadata: { applicationName: 'real-weddings' },
            analyticsProps: {product: "real-weddings"},
            tag: 'latest'
        };
        if (!FooterState) {
            return <div dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToString(<FooterNav ssi {...footerProps} />) }} />;
        }

        return (
            <FooterNav ssi={false} {...footerProps} />
        );
    }
}
