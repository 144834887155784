import React from 'react';
import Icon from '@xo-union/tk-component-icons';


export default class IconAgent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const trackingData = this.props.trackingData || ''

    return (
      <span className={this.props.className} {...trackingData}>
        <Icon name={this.props.iconName} size={this.props.size}/>
      </span>
    );
  }
}
