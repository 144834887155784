import React from "react";
import styles from './lazyload-image.css';

function imageRatioStyle(width, height) {
  if(width && height) {
    return {paddingBottom: `${(height / width).toFixed(3) * 100}%`, height: 0, background: '#f5f6f8'}
  }
}

function mediaSize(width, height) {
  const mediaWidth = parseInt(width) * 2
  const mediaHeight = parseInt(height) * 2
  if (mediaWidth > 0 && mediaHeight > 0) {
    return `sc_${mediaWidth}.${mediaHeight}`
  } else if (mediaWidth > 0) {
    return `rs_${mediaWidth}.h`
  } else {
    return `rs_w.${mediaHeight}`
  }
}

export const LeadImage = (props) => {
  const { width, height, mediaEnv, alt, desktopWidth, desktopHeight, mobileWidth, mobileHeight } = props;
  const containerClassName = props.containerClassName || '';
  const image = props.image || '';
  const imageId = image.split('/').pop().split('~').shift();
  const desktopMediaString = mediaSize(desktopWidth, desktopHeight);
  const mobileMediaString = mediaSize(mobileWidth, mobileHeight);
  const mediaDomain = mediaEnv == '' ? 'www' : 'qa-beta';

  return (
    <div className={`${styles['lazyload-image-container']} ${styles[containerClassName]}`}>
      <div style={imageRatioStyle(width, height)}>
        <picture>
          <source srcset={`https://${mediaDomain}.theknot.com/tk-media/images/${imageId}~${desktopMediaString}?quality=60`} media="(min-width: 768px)" />
          <source srcset={`https://${mediaDomain}.theknot.com/tk-media/images/${imageId}~${mobileMediaString}?quality=60`} media="(min-width: 0px)" />

          <img alt={alt} src={`https://${mediaDomain}.theknot.com/tk-media/images/${imageId}~${desktopMediaString}?quality=60`}/>
        </picture>
      </div>
    </div>
  )
};
