import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { membershipReducer } from '@xo-union/store-membership-redux';
import conversationsReducer, { conversationsMiddleware } from '@xo-union/store-conversations-redux';
import { favoritesReducer, favoriteMiddleware } from '../reducers/favorite-redux';
import { g } from 'xojs/lib/runtime/getGlobal';

const initialState = {};

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = g.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
  membership: membershipReducer,
  conversations: conversationsReducer,
  favoriteData: favoritesReducer
});

const enhancers = composeEnhancers(
  applyMiddleware(
    /* Add plugin to tie membership actions to conversations actions */
    conversationsMiddleware(),
    favoriteMiddleware(),
    /* Our libraries depend on thunk */
    thunk
  )
);

export default createStore(reducer, initialState, enhancers);
