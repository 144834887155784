import * as Types from './action_types'

const initialState = {
  favorites: [],
  toast: undefined
};

// Reducer
export default function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_SUCCESS:
      return {
        ...state,
        favorites: action.favorites,
      };
    case Types.LOVE:
      return {
        ...state,
        favorites: state.favorites.concat(action.favorite),
        toast: 'Added to your Wedding Vision'
      };
    case Types.UNLOVE:
      return {
        ...state,
        favorites: removeFavorite(state.favorites, action.favorite_id),
        toast: 'Removed from your Wedding Vision'
      };
    case Types.CLEAR:
      return initialState;
    case Types.CLEAR_TOAST:
      return {
        ...state,
        toast: undefined
      };
    default:
      return state;
  }
}

function removeFavorite(favorites, favorite_id) {
  let index = favorites.findIndex(function (favorite) {
    return favorite.id === favorite_id
  });

  if(index > -1) {
    favorites.splice(index, 1);
  }

  return favorites;
}