import store from "../../utilities/store";
import React, {Component} from "react";
import { Provider } from 'react-redux';
import FavoriteToastContainer from './FavoriteToastContainer'

export default class Favorite extends Component {

  render() {
    return <Provider store={store} >
      <FavoriteToastContainer {...this.props} ></FavoriteToastContainer>
    </Provider>
  }
}
