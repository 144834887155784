import React from "react";
import PropTypes from "prop-types";
import { Picture, Source, Img } from '@xo-union/tk-component-picture';
import Spinner from './Spinner'
import styles from './lazyload-image.css';

class LazyloadImage extends React.Component {
  constructor(props) {
    super(props);
    this.imgContainer = React.createRef();
    this.state = {
      show_spinner: true
    }
  }

  static defaultProps = {
    lazy: true
  };

  componentDidMount() {
    let img = this.imgContainer.current.querySelector('img');
    let _this = this;
    img.addEventListener('load', function () {
      if (img.complete) {
        _this.setState({
          show_spinner: false
        })
      }
    })
  }

  imageRatioStyle() {
    const {width, height} = this.props;
    if(width && height) {
      return {paddingBottom: `${(height / width).toFixed(3) * 100}%`, height: 0, background: '#f5f6f8'}
    }
  }

  render() {
    let containerClassName = this.props.containerClassName || '';
    let imageClassName = this.props.imageClassName || '';
    let image = this.props.image || '';
    let imageId = image.split('/').pop().split('~').shift();
    let altText = this.props.alt;
    let elementPros = {};
    let { desktopWidth, desktopHeight, mobileWidth, mobileHeight, smartCrop, lazy} = this.props;
    let { show_spinner } = this.state;
    let env = process.env.RAILS_ENV == "production" ? 'production' : 'qa'
    let host = env == "production" ? "https://www.theknot.com/tk-media/images" : "https://qa-beta.theknot.com/tk-media/images"

    return (
      <div className={`${styles['lazyload-image-container']} ${styles[containerClassName]}`} ref={this.imgContainer}>
        <Spinner className={styles['spinner-container']} style={{ display: show_spinner ? 'block' : 'none' }} />
          <div style={this.imageRatioStyle()}>
            <Picture id={imageId} env={env} host={host} lazy={lazy} elementProps={{ className: styles['picture-container'] }}>
              <Source viewport="md" width={desktopWidth} height={desktopHeight} smartCrop={smartCrop} />
              <Source viewport="xs" width={mobileWidth} height={mobileHeight} smartCrop={smartCrop} />

              <Img pixelDensity={1} className={styles[imageClassName]} elementProps={elementPros} width={desktopWidth} height={desktopHeight} alt={altText} smartCrop={smartCrop} lazy={lazy} />
            </Picture>
          </div>
      </div>)
    }
  };

LazyloadImage.propTypes = {
  containerClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string
};

export default LazyloadImage;
