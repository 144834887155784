import React, { Component } from 'react';
import { Link } from '@xo-union/tk-component-buttons';

export default class LinkAgent extends Component {
  static defaultProps = {
    size: 'md',
    color: 'secondary',
    active: false,
    href: '#',
    trackingData: {},
    linkText: '',
    dataClear: false,
    id: null,
    classes: '',
    disabled: null,
    dataApply: false,
    dataSpinnable: false,
    nofollow: false
  }

  render() {
    const { size, color, href, trackingData, linkText, dataClear, active, id, classes, disabled, dataApply, dataSpinnable, nofollow } = this.props;
    const nofollowTag = nofollow ? "nofollow" : ''

    return(
      <Link id={id}
            size={size}
            rel={ nofollowTag }
            color={ active ? 'primary' :  color }
            href={href}
            data-params={ JSON.stringify(trackingData) }
            data-clear={ dataClear ? 'true' : null }
            className={ `link-agent ${classes}` }
            disabled={ disabled }
            data-apply= { dataApply ? "true" : null }
            data-spinnable= { dataSpinnable ? 'true' : null }
            data-track-results-viewed-event={ trackingData['track-results-viewed-event'] }
            data-track-results-viewed-results-type={ trackingData['track-results-viewed-results-type'] }
            data-track-results-viewed-page-count={ trackingData['track-results-viewed-page-count'] }
            data-track-results-viewed-selected={ trackingData['track-results-viewed-selected'] }
            data-track-click-through-to-album-event={ trackingData['track-click-through-to-album-event'] }
            data-tracking-click-through-to-album-album-id={ trackingData['tracking-click-through-to-album-album-id'] }
            data-tracking-click-through-to-album-source={ trackingData['tracking-click-through-to-album-source'] }
            data-photo-tag={ trackingData['photo-tag'] }
            data-album-tag={ trackingData['album-tag'] } >{linkText}</Link>
    )
  }
}