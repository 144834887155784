import React from "react";
import LazyloadImage from "./LazyloadImage";
import { LeadImage } from "./leadImage";

export default class PhotoListingImage extends React.Component {
  static defaultProps = {
    containerClassName: 'photo-listing-container',
    desktopWidth: 298,
    mobileWidth: 333
  };

  render() {
    const {isLeadImage, is_mobile} = this.props;
    let mediaEnv = process.env.RAILS_ENV == 'production' ? '' : 'qa-';

    return (isLeadImage && is_mobile) ?
      <LeadImage
        mediaEnv={mediaEnv}
        {...this.props}
      >
      </LeadImage>
      :
      <LazyloadImage {...this.props} />
  }
}
