import React from 'react';
import IconAgent from './IconAgent';
import LinkAgent from './LinkAgent';
import LocationTypeahead from '@xo-union/tk-component-location-typeahead';
import fieldStyles from '@xo-union/tk-component-fields/lib/components/Field/styles.css';
import styles from '@xo-union/tk-component-fields/lib/components/AsyncTypeahead/styles.css';
import OutsideClickHandler from '@xo-union/component-outside-click';
import customStyles from './HeroSection.css';
import { Masthead, Heading } from '@xo-union/tk-ui-masthead';
import { compose } from '@xo-union/react-css-modules';

export default class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      location: Object.assign(props.location, { stateCode: props.location.state_code })
    }
  }

  render() {
    const { location } = this.state
    const isFilterLocation = Object.keys(location).length > 1
    const locationPrefix =
        this.props.locationText === undefined
        || this.props.locationText.includes("near") ? "" : "in ";

    const trackingData = {}
    for (var key in this.props.trackingData) {
      trackingData['data-' + key] = this.props.trackingData[key]
    }

    return (
        <Masthead
            classes={compose(
                { wrapper: customStyles['custom-wrapper'] })}
            style={{ '--tkww-union-masthead-vertical-padding': '24px' }}
            size="sm"
            heading={<Heading>{this.props.chosenTitle}</Heading>}
            copy={
              <div>
                {this.props.theme} {locationPrefix}
                  <div className={customStyles["secondary-info-text"]} onClick={this.handlePopup}>
                    <a id='location-filter' className={this.props.className} {...trackingData} data-location-filter={isFilterLocation}>{this.props.locationText}</a>
                  </div>
                {this.state.popup === true ? this.popupPage() : ''}
              </div>}
        />
    );
  }
  handlePopup = () => {
    this.setState({ popup: true })
  }

  handleClose = () => {
    this.setState({ popup: false })
  }

  handleLocationSelected = (selected) => {
    this.setState({
      location: selected
    })
  }

  handleClear = () => {
    this.typeahead.setState({ locations: [] });
    this.typeahead._reactInternalFiber.child.child.stateNode.updateQuery('');
    this.setState({ location: {} })
  }

  handleSearch = () => {
    let url = `/real-weddings/${this.props.theme.toLowerCase()}/filters`;
    let selected_location = JSON.stringify(this.state.location);
    let selected_filters = JSON.stringify(TKPL.Filters.selectedFilters);

    if (selected_location == "{}") {
      document.cookie = "location=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    var formData = new FormData();
    formData.append("location", selected_location);
    formData.append("selected_filters", selected_filters);

    fetch(url, {
      method: 'POST',
      headers: {
        "x-csrf-token": $("meta[name='csrf-token']").attr('content'),
      },
      body: formData
    })
        .then(response => response.json())
        .then((data) => {
          window.location = data.redirect_path;
        });
  }

  mergeStyles(defaultStyles, styleNames) {
    styleNames.forEach(function (name, index) {
      let value = defaultStyles[name] || '';
      defaultStyles[name] = `${customStyles[name]} ${value}`
    });
    return defaultStyles;
  }

  popupPage() {
    let classes = this.mergeStyles(styles, ['container', 'item', 'list-container', 'list']);
    let fieldClasses = this.mergeStyles(fieldStyles, ['input', 'field-col']);
    let defaultInputValue = this.state.location.city == undefined ? '' : `${this.state.location.city}, ${this.state.location.stateCode}`

    return (
        <div className={customStyles['modal-container']} style={{ zIndex: 1002 }}>
          <OutsideClickHandler onOutsideClick={this.handleClose}>
            <div id="modal" className={customStyles['modal-dialog']} style={{ zIndex: 1030 }}>
              <div onClick={this.handleClose}>
                <IconAgent className={customStyles['close-icon']} iconName='close' size='md' />
              </div>
              <h3 className={customStyles["modal-title"]}>
                Find Real Wedding {this.props.theme}
              </h3>
              <div className={customStyles['modal-type-ahead']}>
                <LocationTypeahead ref={(typeahead) => { this.typeahead = typeahead }} onChange={this.handleLocationSelected} fieldClasses={fieldClasses} classes={classes} defaultInputValue={defaultInputValue} />
              </div>
              <div className={customStyles['modal-buttons']}>
                <div onClick={this.handleSearch}>
                  <LinkAgent  linkText='Search'
                              color='primary'
                              dataSpinnable={true}/>
                </div>
                <div onClick={this.handleClear}>
                  <LinkAgent  color='tertiary'
                              linkText='Clear'
                              classes={customStyles['modal-clear']}/>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        </div>
    )
  }
}
