import React from 'react';
import HeaderNav from '@xo-union/tk-component-header-nav-redux/remote';
import { Provider } from 'react-redux';
import '@xo-union/tk-ui-essentials';
import store from '../utilities/store';
import '../initializers/loadMember';

const analyticsProps = { product: "real-weddings" };

export default function Header ({}) {
  return (
    <Provider store={store}>
      <div>
        <HeaderNav activeProduct="ideas-advice" metadata={{applicationName: 'real-weddings'}} analyticsProps={analyticsProps} ssi/>
      </div>
    </Provider>
  );
}
