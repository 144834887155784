import React, { Component } from "react";

import LinkAgent from './LinkAgent.jsx';

export default class FilterComponent extends Component {

  static defaultProps = {
    applyTrackingData: {},
  }

  componentDidMount() {
    if(TKPL && TKPL.Filters && TKPL.Filters.init) {
      TKPL.Filters.init();
    }
  }

  render() {
    const { applyTrackingData } = this.props;
    return(
      <div className='row-col-xs-6 row cta-button-row'>
        <div className='col-xs-6 cta-button'>
          <LinkAgent  linkText='Clear'
                      dataClear={true}
                      disabled={false}
                      dataSpinnable={true}/>
        </div>

        <div className='col-xs-6 cta-button'>
          <LinkAgent  classes='apply-button'
                      trackingData={applyTrackingData}
                      linkText='Apply'
                      disabled={false}
                      color='primary'
                      dataApply={true}
                      dataSpinnable={true} />
        </div>
      </div>
    )
  }
}
