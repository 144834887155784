import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from '@xo-union/react-css-modules';
import { loveImage, unloveImage } from '../../utilities/reducers/favorite-redux'
import { Button } from '@xo-union/tk-component-buttons';
import Icon from '@xo-union/tk-component-icons';
import MembershipModalRedux from '@xo-union/tk-component-membership-modal-redux';
import Styles from './Favorite.css';

export class FavoriteComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openModalType: null,
      renderMembershipModal: false
    };

    this.handleClickHeart = this.handleClickHeart.bind(this);
    this.onClickLogIn = this.onClickLogIn.bind(this);
    this.onClickSignUp = this.onClickSignUp.bind(this);
    this.onClose = this.onClose.bind(this);
    this.metadata = this.metadata.bind(this);
    this.renderMembershipModal = this.renderMembershipModal.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.handleLoveOrUnLove = this.handleLoveOrUnLove.bind(this);
    this.renderFavoriteIcon = this.renderFavoriteIcon.bind(this);
    this.renderFavoriteButton = this.renderFavoriteButton.bind(this);
  }

  renderIcon() {
    const { love, with_border } = this.props;
    const heardFilledStyles = love ? Styles['heart-filled'] : '';
    if (with_border === 'true') {
      return <div className={`${Styles['heart-with-border']} ${heardFilledStyles}`}>
        <Icon name="heart_filled" className={`without-outline ${Styles['heart_filled']}`}/>
        <Icon name="heart" className={`without-outline ${Styles['heart_border']}`}/>
      </div>
    }
    return <Icon name={love ? "heart_filled" : 'heart' } className='without-outline'/>
  }

  metadata() {
    return { applicationName: 'real-weddings', userAction: 'attempted to favorite' }
  }

  onClickLogIn() {
    this.setState({ openModalType: 'LOG_IN' });
  }

  onClickSignUp() {
    this.setState({ openModalType: 'SIGN_UP' });
  }

  onClose() {
    this.setState({ renderMembershipModal: false, openModalType: null });
    if (this.props.isLoggedIn && !this.props.love) {
      this.props.loveImage(this.props.favorite_params, this.props.trackingData)
    }
    if (this.props.closePopup) {
      this.props.closePopup();
    }
  }

  renderMembershipModal() {
    if (this.state.renderMembershipModal) {
      return <MembershipModalRedux
        metadata={this.metadata()}
        openModalType={this.state.openModalType}
        onClickLogIn={this.onClickLogIn}
        onClickSignUp={this.onClickSignUp}
        onClose={this.onClose}
        onSignUpSuccess={this.onClose}
        onLogInSuccess={this.onClose}
        additionalMembershipData={{ application: 'real-weddings', action: 'attempted to favorite' }}
      />
    }
  }

  handleLoveOrUnLove() {
    if (!this.props.love) {
      this.props.loveImage(this.props.favorite_params, this.props.trackingData)
    }
    else {
      this.props.unloveImage(this.props.favorite.id, this.props.favorite_params.object, this.props.trackingData)
    }
  }

  handleClickHeart(e) {
    if (!this.props.isLoggedIn) {
      this.setState({ renderMembershipModal: true, openModalType: 'LOG_IN' })
      if(this.props.notHideStickyHeader) {
        this.props.notHideStickyHeader()
      }
    }
    else {
      this.handleLoveOrUnLove()
    }
  }

  renderFavoriteIcon() {
    const className = `${this.props.classes} ${this.props.love ? 'favorited' : 'favorite'}`;
    const { id, favorite_params } = this.props;
    return (
      <div>
        <a noHref data-xo-love="true" id={id} data-params={JSON.stringify(favorite_params)} className={className} onClick={this.handleClickHeart}>
          { this.renderIcon() }
        </a>
        { this.renderMembershipModal() }
      </div>
    )
  }

  renderFavoriteButton() {
    const { love, id, favorite_params, text } = this.props;
    const className = this.props.love ? 'favorited' : 'favorite';
    return (
      <>
        <Button
          id={`button_${id}`}
          data-xo-love="true"
          data-params={JSON.stringify(favorite_params)}
          classes={compose(Styles)}
          className={className}
          color='secondary'
          size="md"
          iconName={love ? 'heart_filled' : 'heart'}
          labelDirection="right"
          onClick={this.handleClickHeart}
        >{text}</Button>
        { this.renderMembershipModal() }
      </>
    );
  }

  render() {
    const { text } = this.props;
    return text ? this.renderFavoriteButton() : this.renderFavoriteIcon();
  }
}

function getFavorite(favorites, favorite_params) {
  return favorites.find(function (favorite) {
    return favorite.item === favorite_params.object && favorite.image_id === favorite_params.image_id
  })
}

export function mapStateToProps(state, ownProps) {
  let favorite = getFavorite(state.favoriteData.favorites, ownProps.favorite_params);

  return {
    love: !!favorite,
    isLoggedIn: !!state.membership.member,
    favorite: favorite
  };
}

export const mapDispatchToProps = {
  loveImage: loveImage,
  unloveImage: unloveImage
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteComponent);
